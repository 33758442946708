// src/serviceWorkerRegistration.js

// This optional code is used to register a service worker.
const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.\d+){0,2}\.\d+$/
      )
  );
  
  export function register(config) {
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", () => {
        // Construct the service worker URL
        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
  
        navigator.serviceWorker
          .register(swUrl)
          .then((registration) => {
            console.log("Service Worker registered with scope: " + registration.scope);
  
            const applySkipWaiting = () => {
              if (registration.waiting) {
                console.log("New service worker is waiting to activate.");
                registration.waiting.postMessage({ type: "SKIP_WAITING" }); // Send message to skip waiting
              }
            };
  
            // Automatically apply skipWaiting if already online
            if (navigator.onLine) {
              registration
                .update()
                .then(() => {
                  applySkipWaiting();
                  console.log("Service Worker updated and activated immediately.");
                })
                .catch((error) =>
                  console.log("Error updating service worker: " + error.message)
                );
            }
  
            // Listen for the `online` event to update dynamically
            window.addEventListener("online", () => {
              registration
                .update()
                .then(() => {
                  applySkipWaiting();
                  console.log("Service Worker updated after coming online.");
                })
                .catch((error) =>
                  console.log("Error updating service worker after coming online: " + error.message)
                );
            });
  
            // Handle new updates by listening for `updatefound`
            registration.addEventListener("updatefound", () => {
              const newWorker = registration.installing;
              if (newWorker) {
                newWorker.addEventListener("statechange", () => {
                  if (newWorker.state === "installed" && navigator.serviceWorker.controller) {
                    console.log("New service worker installed. Activating...");
                    applySkipWaiting();
                  }
                });
              }
            });
  
            // Listen for service worker control changes
            navigator.serviceWorker.addEventListener("controllerchange", () => {
              console.log("New service worker is now controlling the page.");
              console.log("Page is now controlled by the new service worker.");
              if (config?.onUpdate) {
                config.onUpdate(registration);
              }
            });
  
            // Call the success callback if provided
            if (config?.onSuccess) {
              config.onSuccess(registration);
            }
          })
          .catch((error) => {
            console.log("Error during service worker registration: " + error.message);
  
            // Call the failure callback if provided
            if (config?.onError) {
              config.onError(error);
            }
          });
      });
    }
  }
  
  
  
  

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // New content is available; please refresh.
              console.log('New content is available, forcing update.');
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // Content is cached for offline use.
              console.log('Content is cached for offline use.');
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    });
}


function checkValidServiceWorker(swUrl, config) {
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Most likely, the app is not hosted correctly.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister();
      })
      .catch(error => {
        console.error(error.message);
      });
  }
}
  