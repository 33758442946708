import React, { useEffect, useState } from 'react';
import { ref, onValue } from "firebase/database"; // Ensure this is imported from your Firebase setup
import { db } from '../firebase'; // Ensure db is your Firebase Realtime Database instance
import { Link } from 'react-router-dom';
import { FaLaptop, FaMobileAlt, FaMosque, FaSearch, FaTv } from 'react-icons/fa'; // Import mosque icon
import logo from "../assets/logo2.png";
import { Helmet } from 'react-helmet';

function Home() {
  const [masajed, setMasajed] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // State for search query

  // Fetch all users (masajed) from Firebase
  useEffect(() => {
    const masajedRef = ref(db, 'users'); // Get the reference to all users
    const unsubscribe = onValue(masajedRef, (snapshot) => {
      if (snapshot.exists()) {
        const masajedData = snapshot.val();
        const masajedArray = Object.keys(masajedData).map((key) => ({
          ...masajedData[key],
          id: key, // Store the unique user ID
        }));
        setMasajed(masajedArray); // Update the state with the fetched masajed
      }
    });

    return () => unsubscribe(); // Cleanup the listener on unmount
  }, []);

  // Handle search input change
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter masajed by search query (msqname)
  const filteredMasajed = masajed.filter((masjed) =>
    masjed?.msqname?.toLowerCase()?.includes(searchQuery?.toLowerCase()) // Filter by msqname
  );

  return (
    <div className='Home'>
      <Helmet>
        <html lang="ar" />
        <title>ساعة منارة الرقمية للمساجد - الوقت الدقيق والإشعارات الآذانية</title>
        <meta name="description" content="احصل على ساعة رقمية دقيقة للمساجد مع ميزات تخصيص الأذان والخلفيات والمزيد. ساعة منارة توفر لك تجربة متكاملة لإدارة الوقت والصلوات بشكل سهل وفعّال." />
        
        {/* <!-- Open Graph Meta Tags --> */}
        <meta property="og:title" content="ساعة منارة الرقمية للمساجد - الوقت الدقيق والإشعارات الآذانية" />
        <meta property="og:description" content="احصل على ساعة رقمية متكاملة للمساجد. خصائص الأذان المخصص، الخلفيات، وعرض الإعلانات تجعلها الخيار الأمثل لكل مسجد." />
        <meta property="og:image" content="	https://images.pexels.com/photos/16423534/pexels-p…-at-the-ali-kuscu-mosque-at-istanbul-airport.jpeg" />
        <meta property="og:url" content="https://manarahclock.com" />
        <meta property="og:type" content="website" />

        {/* <!-- Twitter Card Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="ساعة منارة الرقمية للمساجد - الوقت الدقيق والإشعارات الآذانية" />
        <meta name="twitter:description" content="ساعة رقمية متكاملة للمساجد مع تخصيص كامل للأذان والخلفيات والمزيد. تجربة فريدة لإدارة الوقت بدقة." />
        <meta name="twitter:image" content="	https://images.pexels.com/photos/16423534/pexels-p…-at-the-ali-kuscu-mosque-at-istanbul-airport.jpeg" />

        {/* <!-- Additional SEO Tags --> */}
        <meta name="keywords" content="ساعة رقمية, ساعة للمساجد, ساعة أذان, ساعة منارة, تخصيص الأذان, إدارة الوقت" />
        <link rel="canonical" href="https://manarahclock.com" />
      </Helmet>

      <nav>
        <img src={logo} alt="Logo" />
        <div>
          <a href='#footer'>تواصل معنا</a>
          <Link to="/client/login">تسجيل دخول</Link>
        </div>
      </nav>

      <section className='Hero'>
        <h1>ساعة منارة الرقمية للمساجد</h1>
        <h2>أفضل ساعة لمساجدكم لعرض أوقات الصلاة</h2>
      </section>

      <section className='Masajed'>
        <div>
          <h1>ابحث عن مسجدك</h1>
          {/* Search input */}
          <div className='Search'>
            <input
              type='text'
              placeholder='ابحث عن المسجد'
              value={searchQuery}
              onChange={handleSearch}
            />
            <FaSearch />
          </div>
          {/* All Masajed Holder */}
          <div className='masajedHolder'>
            {filteredMasajed.length > 0 ? (
              filteredMasajed.map((masjed) => {
                const emailUsername = masjed.email.split('@')[0]; // Get the part before '@' from email

                return (
                  <Link to={`/masjid/${emailUsername}`} key={masjed.id} className='masjed-card'>
                    <div>
                      <FaMosque size={40} /> {/* Mosque Icon */}
                      <h3>{masjed.msqname}</h3> {/* Masjed name in Arabic */}
                    </div>
                  </Link>
                );
              })
            ) : (
              <p>لا توجد نتائج</p> // Display when no masajed match the search query
            )}
          </div>
          <span>ابحث عن المسجد الذي ترغب فيه من بين المساجد المتوفرة لدينا باستخدام الاسم.</span>
        </div>
      </section>

      <section className='AboutTheClock'>
          <div>
            <img src={logo}></img>
            <p>ساعة منارة الرقمية للمساجد تعرض أوقات الصلاة، الإقامة، التاريخ والوقت بشكل عصري ومخصص لكل مسجد. يمكن عرضها على أي تلفاز بحجم مناسب، ويمكن لجماعة المسجد متابعة المسجد عبر هواتفهم أو التلفاز الذكي باستخدام الرابط المخصص</p>
            <span>
              <a href='tel:+966554344899'>اطلبها الآن</a>
              <Link to="/client/login">تسجيل دخول</Link>
            </span>
          </div>
      </section>
      
      <section className='AboutForSEO'>
        <div className='responsiveDesign'>
          <img src={require("../assets/screencapture-manarah-clock-netlify-app-masjid-test-2024-11-10-12_01_20.png")} alt='https://manarah-clock.netlify.app/masjid/test'></img>
          <div>
            <h1>تصميم عصري ومتجاوب</h1>
            <p>تتميز ساعة مانارة بتصميم عصري يجمع بين الأناقة والوظائف المتعددة، فهي متجاوبة تمامًا وتتكيف مع جميع أحجام الشاشات لتقدم لك تجربة عرض مريحة وسهلة الاستخدام.</p>
            <a href='#footer'>تواصل معنا</a>
          </div>
        </div>
        <div className='optimized'>
          <h1>مصممة للعمل بكفاءة على </h1>
          <h2><span><FaTv />تلفزيون ذكي</span><span> <FaMobileAlt />هاتف</span><span><FaLaptop />حاسوب</span>  </h2>
          <p>تم تحسين ساعة مانارة لتعمل بسلاسة عبر جميع الأجهزة، سواء كانت شاشة تلفاز كبيرة، حاسوب مكتبي، أو هاتف ذكي، لتضمن لك تجربة مشاهدة متكاملة بدون أي تنازلات في الأداء أو الجودة.</p>
        </div>
        <div className='admindashbored'>
          <img src={require("../assets/Screenshot 2024-11-10 165925.png")}></img>
          <div>
          <h1>تخصيص ساعتك</h1>
          <p>
            من خلال لوحة التحكم، يمكنك تخصيص ساعتك لتتناسب مع تفضيلاتك الشخصية. تتوفر خيارات عديدة مثل:
          </p>
          <ul>
            <li>
              <strong>خلفية الشاشة</strong> اختر خلفية مخصصة لتضفي لمسة شخصية على واجهة الساعة وتجعلها أكثر جاذبية.
            </li>
            <li>
              <strong>صوت الأذان</strong> حدد صوت الأذان الذي تفضله ليتم تشغيله في أوقات الصلاة، لإضفاء طابع روحاني على تجربتك.
            </li>
            <li>
              <strong>الإعلانات والأحاديث</strong> يمكنك إضافة إعلانات أو أحاديث نبوية تظهر بشكل دوري على الساعة، لتذكيرك وتقديم محتوى قيم.
            </li>
          </ul>
          </div>
        </div>

      </section>

      <section className='AboutTheCreator'>
        <div>
          <h1>تم ابتكار هذا المنتج من قبل <span>سلالم الإبداع</span></h1>
          <p>سلالم الإبداع، مؤسسة سعودية في جدة تقدم حلول مبتكرة في التكنولوجيا الناشئة، المعارض، التأثيث، الإعلام، وتكنولوجيا المعلومات. نعتبر أنفسنا "مختبر ابتكار" لدفع حدود التكنولوجيا ودعم السوق المحلي بحلول متقدمة.</p>
          <a href='https://www.innovationladders.com/'>استكشف منتجاتنا</a>
        </div>
      </section>

      <footer id='footer'>
        <div>
          <img src={logo}></img>
          <label>© 2024 Innovation Ladders - Jeddah - Saudi Arabia</label>
        </div>
      </footer>
    </div>
  );
}

export default Home;
