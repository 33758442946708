import React, { useState, useEffect, useRef, useMemo } from 'react';

import { ref, get } from 'firebase/database';
import { useNavigate, useParams } from 'react-router-dom';

import { IoMenu } from 'react-icons/io5';

import { db } from '../firebase'; // Import your Firebase config
import "../style/clock.css";

import { useMediaQuery } from 'react-responsive'

import { MdLogout, MdOutlineClose, MdOutlineMosque } from 'react-icons/md';
import { FiVolume2, FiVolumeX } from 'react-icons/fi';

import clickSound from "../assets/click.mp3"
import { Helmet } from 'react-helmet';



import PrayTimes from "../PrayTimes"
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      window.location.reload();
    }
  },
});

function Clock() {
  const { masjidName } = useParams(); // Retrieve emailUserName from URL
  const navigate = useNavigate(); // To redirect if needed
  const clockRef = useRef(null); // Reference to the clock component for full-screen
  const adhanAudioRef = useRef(null); // Ref to the adhan audio element

  // State variables for mosque data
  const [masjedData, setmasjedData] = useState({
    msqName :'',
    backgroundUrl : '',
    adhanUrl : '' ,
    ads : '',
    hadeeth : '',
    lat : '',
    long : '' ,
    fajrOffset : '',
    sunriseOffset : '',
    dhuhrOffset : '',
    asrOffset : '',
    maghribOffset : '',
    ishaOffset : '',
    saletDuration : {},
  })

  const [hijriDate, setHijriDate] = useState(""); // State For Hijri Date
  const [gregorianDate, setGregorianDate] = useState(""); // State for Gregorian date
  const [currentTime, setCurrentTime] = useState(""); // State for real-time clock


  
  const [highlightedSalet, sethighlightedSalet] = useState(1)


  const [nextAdhenIn, setnextAdhenIn] = useState("");
  const [prayerTimes, setPrayerTimes] = useState({});
  const [establishmentTimes, setEstablishmentTimes] = useState({});

  const [screen, setscreen] = useState(null)
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })


  const [toggleMenu, settoggleMenu] = useState(false)
  const [soundActive, setsoundActive] = useState(false)
  
  const [firstLoad, setfirstLoad] = useState(true)

  const [currentPrayer, setCurrentPrayer] = useState({})


  const [showRemainingForIkama, setshowRemainingForIkama] = useState(false)
  const [remainingTimeForIkama, setremainingTimeForIkama] = useState("")
  // Toggle Full Screen
  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      clockRef.current.requestFullscreen().catch(err => {
        console.error(`Error attempting to enable full-screen mode: ${err.message}`);
      });
    } else {
      document.exitFullscreen();
    }
  };

  // Next Adhen Time
  const getNextAdhanTime = (prayerTimes) => {
    const now = new Date();
  
    if (!Object.keys(prayerTimes).length) {
      console.error('Prayer times not available yet');
      return null;
    }
  
    const prayerTimesToday = Object.entries(prayerTimes).map(([prayer, time]) => {
      if (!time) return null;
  
      const [hours, minutes] = time.split(':').map(Number);
      const adhanTime = new Date(now);
  
      // Determine if the time is AM or PM based on prayer name
      const isPM = ['Dhuhr', 'Asr', 'Maghrib', 'Isha'].includes(prayer);
  
      adhanTime.setHours(
        hours % 12 + (isPM ? 12 : 0), // Convert to 24-hour format for PM prayers
        minutes,
        0,
        0
      );
  
      return adhanTime;
    }).filter(adhanTime => adhanTime !== null);
  
    // Filter out any prayer times that have already passed today
    const futurePrayerTimes = prayerTimesToday.filter(adhanTime => {
      return adhanTime > now;
    });
  
    if (futurePrayerTimes.length === 0) {
      const [fajrHours, fajrMinutes] = prayerTimes?.Fajr?.split(':')?.map(Number) || [];
      if (fajrHours !== undefined && fajrMinutes !== undefined) {
        const nextDayFajr = new Date(now);
        nextDayFajr.setDate(nextDayFajr.getDate() + 1);
        nextDayFajr.setHours(fajrHours, fajrMinutes, 0, 0);
        return nextDayFajr;
      }
      return null;
    }
  
    
    if (firstLoad) {
      sethighlightedSalet(7 - futurePrayerTimes.length);
      setfirstLoad(false);
    }
  
    return futurePrayerTimes[0];
  };

  // Calculate establishment times
  const calculateEstablishmentTimes = (apiResponse) => {
    const timings = apiResponse;
    
    // Default establishment offsets in minutes (customize as needed)
    const offsets = {
      Fajr: masjedData.fajrOffset,    // DF 10 minutes after adhan for Fajr
      Sunrise: masjedData.sunriseOffset, // DF 10 minutes after adhan for Sunrise
      Dhuhr: masjedData.dhuhrOffset,   // DF 15 minutes after adhan for Dhuhr
      Asr: masjedData.asrOffset,     // DF 10 minutes after adhan for Asr
      Maghrib: masjedData.maghribOffset,  // DF 5 minutes after adhan for Maghrib
      Isha: masjedData.ishaOffset     // DF 15 minutes after adhan for Isha
    };

    const newEstablishmentTimes = {};
    for (const [prayer, time] of Object.entries(timings)) {
      

      const [hours, minutes] = time.split(':').map(Number);
      const adhanTime = new Date();
      adhanTime.setHours(hours % 12 + (hours >= 12 ? 12 : 0), minutes, 0, 0);

      // Add the offset (establishment time)
      adhanTime.setMinutes(adhanTime.getMinutes() + offsets[prayer]);

      // Format the establishment time as HH:MM in 12-hour format without AM/PM
      const hours12 = adhanTime.getHours() % 12 || 12; // Convert to 12-hour format
      const minutesFormatted = String(adhanTime.getMinutes()).padStart(2, '0');

      newEstablishmentTimes[prayer] = `${hours12}:${minutesFormatted}`; // Removed AM/PM
    }

    setEstablishmentTimes(newEstablishmentTimes);
  };

  // Function to format prayer times with AM/PM
  const formatTimesWithAmPm = (times) => {
    return Object.entries(times).reduce((formatted, [prayerName, time], index) => {
      const [hour, minute] = time.split(":").map(Number);
      const isAm = index < 2; // First two prayers are AM, rest are PM
      const formattedHour = isAm ? hour : hour % 12 || 12; // Convert to 12-hour format for PM
      const suffix = isAm ? "AM" : "PM";
      formatted[prayerName] = `${formattedHour}:${minute.toString().padStart(2, "0")} ${suffix}`;
      return formatted;
    }, {});
  };
  // Memoize formatted prayerTimes and establishmentTimes
  const formattedPrayerTimes = useMemo(() => formatTimesWithAmPm(prayerTimes), [prayerTimes]);
  const formattedEstablishmentTimes = useMemo(() => formatTimesWithAmPm(establishmentTimes), [establishmentTimes]);
  const timeUntilRestToNormalRef = useRef("");
  const timeToChangeNextHighlitedSaletRef = useRef("");
  const currentPrayerIndex = useRef(1)
  // Schedule the next Adhan using setInterval
  const adhanIntervalRef = useRef(null);  // Store the interval ID to clear it later
  function addMinutesToTime(timeString, minutesToAdd) {
    // Parse the timeString (e.g., "3:45 PM")
    const [time, period] = timeString.split(" ");
    const [hour, minute] = time.split(":").map(Number);
  
    // Convert to 24-hour format
    let hours24 = period === "PM" && hour !== 12 ? hour + 12 : hour;
    hours24 = period === "AM" && hour === 12 ? 0 : hours24;
  
    // Create a new Date object and set the time
    const date = new Date();
    date.setHours(hours24);
    date.setMinutes(minute);
  
    // Add the specified minutes
    date.setMinutes(date.getMinutes() + minutesToAdd);
  
    // Convert back to 12-hour format
    const newHour = date.getHours();
    const newMinute = date.getMinutes().toString().padStart(2, "0");
    const newPeriod = newHour >= 12 ? "PM" : "AM";
    const formattedHour = newHour % 12 || 12; // Convert 0 to 12 for 12-hour format
  
    return `${formattedHour}:${newMinute} ${newPeriod}`;
}

  const scheduleNextAdhan = () => {
    let tillnextMinute = 60;
    
    const checkNextAdhan = () => {
      const now = new Date();
      const hours = now.getHours(); // Get the current hour (0-23)
      const minutes = now.getMinutes().toString().padStart(2, "0"); // Get the current minute, padded to two digits

      // Convert current time to 12-hour format without AM/PM
      const formattedHours = hours % 12 || 12; // Convert 0 -> 12 for 12-hour format

      // Determine AM or PM
      const period = hours >= 12 ? 'PM' : 'AM';

      // Combine hours, minutes, and period
      const timeString = `${formattedHours}:${minutes} ${period}`;
      
      // detect if its time to go back to normal state
      if(timeUntilRestToNormalRef.current === timeString){
        setState("normal")
        timeUntilRestToNormalRef.current = ""
      }
      // detect if its time change current salet
      if(timeToChangeNextHighlitedSaletRef.current === timeString){
        timeToChangeNextHighlitedSaletRef.current = ""
        sethighlightedSalet(currentPrayerIndex.current)
      }
      // every minute checker
      if(tillnextMinute - now.getSeconds() === 60){
        // Try To connect with the serviec worker
        try {
          navigator.serviceWorker.controller.postMessage({
            type: "heartbeat",
            date : now,
          });
        } catch (error) {
          console.error(error)
        }

        // Iterate over the prayer times and check for a match
        for (const [prayerName, prayerTime] of Object.entries(formattedPrayerTimes)) {
          
          if (prayerTime === timeString) {
            setState("adhan");
            setshowRemainingForIkama(true)
            // Set timeout to hide Wamidh after the audio finishes
            const duration = adhanAudioRef?.current?.duration * 1000;
            setTimeout(() => {
              setState("normal");
            }, isNaN(duration) ? 60000 : duration);

            if (adhanAudioRef.current) {
              adhanAudioRef.current
                .play()
                .then(() => console.log("Adhan audio played successfully."))
                .catch((error) =>
                  console.log(`Adhan audio failed to play: ${error.message}`)
                );
            }
            // Map prayer names to their index
            const prayerIndexMap = {
              fajr: 1,
              sunrise: 2,
              dhuhr: 3,
              asr: 4,
              maghrib: 5,
              isha: 6,
            };

            // Set the highlightedSalet based on the current prayer            
            currentPrayerIndex.current = (prayerIndexMap[prayerName.toLowerCase()] === 6 ? 1 : prayerIndexMap[prayerName.toLowerCase()]+1 ) || 1
            timeToChangeNextHighlitedSaletRef.current = addMinutesToTime(timeString,30)
            clearInterval(adhanIntervalRef.current);
            adhanIntervalRef.current = setInterval(checkNextAdhan, 1000);
            break; // Stop checking once a match is found
          }
        }

        // Iterate over the ikama times and check for a match
        for (const [prayerName, prayerTime] of Object.entries(formattedEstablishmentTimes)) {
          if (prayerTime === timeString) {
            setState("ikama")
            setshowRemainingForIkama(false)
            
            timeUntilRestToNormalRef.current = addMinutesToTime(timeString,masjedData.saletDuration[prayerName?.toLowerCase() === "dhuhr" ?"dhuher":prayerName?.toLowerCase()])
            clearInterval(adhanIntervalRef.current);
            adhanIntervalRef.current = setInterval(checkNextAdhan, 1000);
            break; // Stop checking once a match is found
          }
        }
         
      }
      // setting current clock time
      setCurrentTime(now.toLocaleTimeString('en-US', { hour12: true }))
      // getting how still until ikama time
      getNextIkamaTime(establishmentTimes)
      const nextAdhanTime = getNextAdhanTime(prayerTimes);  // Assuming this function is defined elsewhere
      if (nextAdhanTime) {
        
        const timeUntilNextAdhan = nextAdhanTime - now;
        if (timeUntilNextAdhan <= 1500 && timeUntilNextAdhan >= -500) {
          console.log("It's time for the Adhan:", nextAdhanTime.toLocaleTimeString());
          
        } 
        // else {
        //   // Display time remaining until the next Adhan
        //   const hoursLeft = Math.floor(timeUntilNextAdhan / 3600000);
        //   const minutesLeft = Math.floor((timeUntilNextAdhan % 3600000) / 60000);
        //   const secondsLeft = Math.floor((timeUntilNextAdhan % 60000) / 1000);

        //   setnextAdhenIn(` الوقت المتبقي ${secondsLeft} : ${minutesLeft} : ${hoursLeft}`);
        // }
      }
    };

    // Clear previous interval if any before setting a new one
    if (adhanIntervalRef.current) {
      clearInterval(adhanIntervalRef.current);
    }

    // Set interval to check every second
    adhanIntervalRef.current = setInterval(checkNextAdhan, 1000);
  };
  
  // Convert time to 12-hour format without AM/PM
  const convertTo12HourFormat = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    const hours12 = hours % 12 || 12; // Convert to 12-hour format
    const minutesFormatted = String(minutes).padStart(2, '0');
    return `${hours12}:${minutesFormatted}`; // Format as HH:MM
  };
  
  // Get Hijri Date (Intl with Umm al-Qura Approximation)
  const fetchHijriDate = () => {
    const today = new Date();

    // Format the date using the 'islamic' calendar in Arabic (approximates Umm al-Qura)
    const hijriFormatter = new Intl.DateTimeFormat('ar-SA-u-ca-islamic-umalqura', { dateStyle: 'full' });

    // Format the date using the Gregorian calendar in English
    const gregorianFormatter = new Intl.DateTimeFormat('en', { dateStyle: 'full' });

    // Get Hijri date
    const formattedHijriDate = hijriFormatter.format(today);

    // Get Gregorian date
    const formattedGregorianDate = gregorianFormatter.format(today);

    // Assuming you have state hooks for both dates
    setHijriDate(formattedHijriDate);
    setGregorianDate(formattedGregorianDate);
  };

  // Fetch Clock Data From Firebase
  useEffect(() => {
    const fetchData = async () => {
      const refRec = ref(db, `users/${masjidName}`);
      
      try {
        const snapshot = await get(refRec);
        if (snapshot.exists()) {
          const data = snapshot.val();
          setmasjedData({
            msqName :data.msqname || '',
            backgroundUrl : data.backgroundImage || '',
            adhanUrl : data.adhan || '' ,
            ads : data.advertisement || '',
            hadeeth : data.hadeeth || '',
            lat : data.lat || '',
            long :data.long || '' ,
            fajrOffset : Number(data.fajr) || '',
            sunriseOffset : Number(data.sunrise) || '',
            dhuhrOffset : Number(data.dhuhr) || '',
            asrOffset : Number(data.asr) || '',
            maghribOffset : Number(data.maghrib) || '',
            ishaOffset : Number(data.isha) || '',
            saletDuration : data.saletDuration || {
              fajr: 15,
              sunrise: 15,
              dhuher: 15,
              asr: 15,
              maghrib: 15,
              isha: 15
            },
          })

          setscreen(data.screen?.toUpperCase() || '');

          // Save data to local storage
          localStorage.setItem('masjidData', JSON.stringify(data));

          // Cache adhan sound and background image
          await cacheAssets(data.adhan, data.backgroundImage);
          
          
          // Fetch Hijri date and prayer times using lat and long
          fetchHijriDate(data.lat, data.long);
          
        } else {
          console.error('User not found in Firebase');
          navigate('/masjid/test');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        navigate('/masjid/test');
      }
    };

    const cacheAssets = async (adhanUrl, backgroundUrl) => {
      if ('caches' in window) {
        const cache = await caches.open('dynamic-cache');
        
        if (adhanUrl) {
          try {
            const response = await fetch(adhanUrl, { mode: 'no-cors' });
            await cache.put(adhanUrl, response.clone());
            console.log('Cached adhan URL');
          } catch (err) {
            console.error('Failed to fetch or cache adhan:', err);
          }
        }
    
        if (backgroundUrl) {
          try {
            const response = await fetch(backgroundUrl, { mode: 'no-cors' });
            await cache.put(backgroundUrl, response.clone());
            console.log('Cached background URL');
          } catch (err) {
            console.error('Failed to fetch or cache background:', err);
          }
        }
      }
    };
    // Check if online and fetch data
    const handleFetch = () => {
      if (navigator.onLine) {
        fetchData();
      } else {
        // If offline, retrieve data from local storage
        const savedData = localStorage.getItem('masjidData');
        if (savedData) {
          const data = JSON.parse(savedData);
          setmasjedData({
            msqName :data.msqname || '',
            backgroundUrl : data.backgroundImage || '',
            adhanUrl : data.adhan || '' ,
            ads : data.advertisement || '',
            hadeeth : data.hadeeth || '',
            lat : data.lat || '',
            long :data.long || '' ,
            fajrOffset : Number(data.fajr) || '',
            sunriseOffset : Number(data.sunrise) || '',
            dhuhrOffset : Number(data.dhuhr) || '',
            asrOffset : Number(data.asr) || '',
            maghribOffset : Number(data.maghrib) || '',
            ishaOffset : Number(data.isha) || '',
            saletDuration : data.saletDuration || {
              fajr: 15,
              sunrise: 15,
              dhuher: 15,
              asr: 15,
              maghrib: 15,
              isha: 15
            },
          })

        
          setscreen(data.screen?.toUpperCase() || '');

          // Fetch Hijri date and prayer times using lat and long
          fetchHijriDate(data.lat, data.long);
        } else {
          console.error('No data available in local storage');
          navigate('/masjid/test');
        }
      }
    };

    handleFetch();

    // Optionally, listen for online/offline events
    window.addEventListener('online', handleFetch);
    window.addEventListener('offline', handleFetch);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('online', handleFetch);
      window.removeEventListener('offline', handleFetch);
    };
  }, [masjidName, navigate]);

  const [date, setDate] = useState(new Date()); // State for the current date
  // Initial The Prayer Times 
  useEffect(() => {
    if (masjedData.lat && masjedData.long) {
      try {
        const PT = new PrayTimes('Makkah');
        const times = PT.getTimes(date, [masjedData.lat, masjedData.long], +3);
  
        console.log("Prayer Times:", times);
  
        setPrayerTimes({
          Fajr: convertTo12HourFormat(times.fajr),
          Sunrise: convertTo12HourFormat(times.sunrise),
          Dhuhr: convertTo12HourFormat(times.dhuhr),
          Asr: convertTo12HourFormat(times.asr),
          Maghrib: convertTo12HourFormat(times.maghrib),
          Isha: convertTo12HourFormat(times.isha),
        });

      } catch (error) {
        console.error("Error calculating prayer times or scheduling Adhan:", error);
      }
    }
  
    // Cleanup interval on unmount
    return () => {
      if (adhanIntervalRef.current) {
        clearInterval(adhanIntervalRef.current);
      }
    };
  }, [date, masjedData]);
  

  // Calculate ikama Time
  useEffect(() => {
    calculateEstablishmentTimes(prayerTimes); // Calculate establishment times 
  }, [prayerTimes])

  // Preload Audio 
  useEffect(() => {
    // Preload the audio when the component mounts
    const audio = new Audio(masjedData.adhanUrl);
    adhanAudioRef.current = audio;

    // You can also preload it by setting preload to 'auto'
    audio.preload = 'auto';
    
    // Optional: Attach a load event to ensure the audio is preloaded
    audio.addEventListener('canplaythrough', () => {
      console.log('Audio is preloaded and ready to play');
    });

    return () => {
      // Clean up the audio reference if the component unmounts
      audio.removeEventListener('canplaythrough', () => {});
    };
  }, [masjedData.adhanUrl]);

  // Request To Keep App Live
  useEffect(() => {
    // Check if requestIdleCallback is supported
    if ('requestIdleCallback' in window) {
      const idleCallback = () => {
        // Ensure the app stays alive in idle periods by performing minimal tasks
        console.log("App is idle but still alive");
      };
  
      // Request the idle callback to run when the browser is idle
      const idleCallbackId = window.requestIdleCallback(idleCallback, { timeout: 5000 });
  
      // Cleanup function to cancel the idle callback when the component unmounts
      return () => {
        if (idleCallbackId) {
          window.cancelIdleCallback(idleCallbackId);
        }
      };
    }
  }, []);  // Empty dependency array ensures this effect runs only once when the component mounts
  

  const [state, setState] = useState("normal")
  const getNextIkamaTime = (establishmentTimes) => {
    const now = new Date();
  
    if (!Object.keys(establishmentTimes).length) {
      console.error('Ikama times not available yet');
      return null;
    }
  
    const ikamaTimesToday = Object.entries(establishmentTimes).map(([prayer, time]) => {
      if (!time) return null;
  
      const [hours, minutes] = time.split(':').map(Number);
      const ikamaTime = new Date(now);
  
      // Determine if the time is AM or PM based on prayer name
      const isPM = ['Dhuhr', 'Asr', 'Maghrib', 'Isha'].includes(prayer);
  
      ikamaTime.setHours(
        hours % 12 + (isPM ? 12 : 0), // Convert to 24-hour format for PM prayers
        minutes,
        0,
        0
      );
  
      return ikamaTime;
    }).filter(ikamaTime => ikamaTime !== null);
  
    // Filter out any ikama times that have already passed today
    const futureIkamaTimes = ikamaTimesToday.filter(ikamaTime => {
      return ikamaTime > now;
    });
  
    if (futureIkamaTimes.length === 0) {
      const [fajrHours, fajrMinutes] = establishmentTimes?.Fajr?.split(':')?.map(Number) || [];
      if (fajrHours !== undefined && fajrMinutes !== undefined) {
        const nextDayFajr = new Date(now);
        nextDayFajr.setDate(nextDayFajr.getDate() + 1);
        nextDayFajr.setHours(fajrHours, fajrMinutes, 0, 0);
        return formatRemainingTime(nextDayFajr - now);
      }
      return null;
    }
  
    const nearestIkamaTime = futureIkamaTimes[0]; // Get the nearest Ikama time
    setremainingTimeForIkama(formatRemainingTime(nearestIkamaTime - now));
    return 1; 
  };
  
  // Helper function: Format remaining time in "mm:ss"
  function formatRemainingTime(remainingTime) {
    const minutes = Math.floor(remainingTime / 60000); // Full minutes
    const seconds = Math.floor((remainingTime % 60000) / 1000); // Remaining seconds
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
  
  useEffect(() => {
    const messageHandler = (event) => {
      const { type, prayer ,error,prayerTimes , formattedHijriDate ,formattedGregorianDate} = event.data;
      console.log(type);
      
      if (type === "ADHAN") {
        setState("adhan");
        setCurrentPrayer(prayer);
        setshowRemainingForIkama(true)
        // Set timeout to hide Wamidh after the audio finishes
        const duration = adhanAudioRef?.current?.duration * 1000;
        setTimeout(() => {
          setState("normal");
          
        }, isNaN(duration) ? 60000 : duration);

        if (adhanAudioRef.current) {
          adhanAudioRef.current
            .play()
            .then(() => console.log("Adhan audio played successfully."))
            .catch((error) =>
              console.log(`Adhan audio failed to play: ${error.message}`)
            );
        }
        // Map prayer names to their index
        const prayerIndexMap = {
          fajr: 1,
          sunrise: 2,
          dhuher: 3,
          asr: 4,
          maghrib: 5,
          isha: 6,
        };

        // Set the highlightedSalet based on the current prayer
        const currentPrayerIndex = prayerIndexMap[prayer.toLowerCase()] || 0; // Default to 0 if not found
        setTimeout(() => {
          sethighlightedSalet(currentPrayerIndex);
        }, 1000 * 60 * 20);
      } else if (type === "IKAMA") {
        
        
        setState("ikama")
        setshowRemainingForIkama(false)
        console.log(masjedData.saletDuration);
        console.log(prayer?.toLowerCase());
        
        setTimeout(() => {
          setState("normal")
        }, masjedData.saletDuration[prayer?.toLowerCase()]* 60 * 1000);
        
      }else if(type === "setHijriDate") {
        setHijriDate(formattedHijriDate)
      }else if(type === "setGregorianDate") {
        setGregorianDate(formattedGregorianDate)
      }else if(type === "REQUEST_PRAYER_TIMES"){
        if (navigator.serviceWorker.controller) {
          navigator.serviceWorker.controller.postMessage({
            type: "SET_PRAYER_TIMES",
            prayerTimes: [
              {
                name: "fajr",
                adhan : prayerTimes["Fajr"],
                ikama : establishmentTimes["Fajr"]
              },
              {
                name: "sunrise",
                adhan :prayerTimes["Sunrise"],
                ikama : establishmentTimes["Sunrise"]
              },
              {
                name: "dhuhr",
                adhan :prayerTimes["Dhuhr"],
                ikama : establishmentTimes["Dhuhr"]
              },
              {
                name: "asr",
                adhan : prayerTimes["Asr"],
                ikama : establishmentTimes["Asr"]
              },
              {
                name: "maghrib",
                adhan : prayerTimes["Maghrib"],
                ikama : establishmentTimes["Maghrib"]
              },
              {
                name: "isha",
                adhan : prayerTimes["Isha"],
                ikama : establishmentTimes["Isha"]
              }
            ],
          });
        }
      }else if(type === "ERROR"){
        setCurrentTime(error)
      }
    };

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("message", messageHandler);
    }

    return () => {
      if (navigator.serviceWorker) {
        navigator.serviceWorker.removeEventListener("message", messageHandler);
      }
    };
  }, [masjedData]);


  useEffect(() => {
    const sendPrayerTimes = () => {
      if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
          type: "SET_PRAYER_TIMES",
          prayerTimes: [
            {
              name: "fajr",
              adhan: prayerTimes["Fajr"],
              ikama: establishmentTimes["Fajr"],
            },
            {
              name: "sunrise",
              adhan: prayerTimes["Sunrise"],
              ikama: establishmentTimes["Sunrise"],
            },
            {
              name: "dhuher",
              adhan: prayerTimes["Dhuhr"],
              ikama: establishmentTimes["Dhuhr"],
            },
            {
              name: "asr",
              adhan: prayerTimes["Asr"],
              ikama: establishmentTimes["Asr"],
            },
            {
              name: "maghrib",
              adhan: prayerTimes["Maghrib"],
              ikama: establishmentTimes["Maghrib"],
            },
            {
              name: "isha",
              adhan: prayerTimes["Isha"],
              ikama: establishmentTimes["Isha"],
            },
          ],
        });
      }
    };
    sendPrayerTimes()
    
  }, [prayerTimes, establishmentTimes]);

  useEffect(() => {
    // Schedule the next Adhan
    scheduleNextAdhan({
      Fajr: prayerTimes.fajr,
      Sunrise: prayerTimes.sunrise,
      Dhuhr: prayerTimes.dhuhr,
      Asr: prayerTimes.asr,
      Maghrib: prayerTimes.maghrib,
      Isha: prayerTimes.isha,
    });
    return ()=>clearInterval(adhanIntervalRef.current);
  }, [establishmentTimes,firstLoad])
  
  return (
    <div
      ref={clockRef}
      className="clock"
      onDoubleClick={toggleFullScreen}
      style={{writingMode : !isMobile && screen === "V" ? 'tb' : 'initial' , paddingBottom : !isMobile && screen === "H" ? '1em' : "auto"}}
    >
      
      <Helmet>
          <title>{masjedData.msqName}</title>
      </Helmet>

      {!isMobile && <img src={masjedData.backgroundUrl} className='backgroundImage'></img>}
      {isMobile && <img src={require("../assets/mobile.jpg")} className='backgroundImage'></img>}
      
      <nav style={{padding : !isMobile && screen === "V" ? '2em 1.5em' : isMobile  ? ".5em 1em":"1em 2em 0em 2em"}}>
        <img src={require("../assets/logo2.png")} alt="Logo" style={{transform : !isMobile && screen === "V" ? 'rotate(90deg) translateY(35px) translateX(19px)' : 'none',transformOrigin : !isMobile && screen === "V" ? 'right' : 'none'}}/>
        <IoMenu onClick={()=>settoggleMenu(true)} style={{transform : !isMobile && screen === "V" ? 'rotate(90deg) translateY(16px)' : 'none'}}/>
      </nav>

      
      {toggleMenu && <div className='Menu'>
        <nav style={{padding : !isMobile && screen === "V" ? '2em 1.5em' : '1em 1.5em'}}>
          <img src={require("../assets/logo2.png")} alt="Logo" style={{transform : !isMobile && screen === "V" ? 'rotate(90deg) translateY(35px) translateX(19px)' : 'none',transformOrigin : !isMobile && screen === "V" ? 'right' : 'none'}}/>
          <MdOutlineClose onClick={()=>settoggleMenu(false)}  style={{transform : !isMobile && screen === "V" ? 'rotate(90deg) translateY(16px)' : 'none'}}/>
        </nav>
        <div className='insideMenuHolder'>
          <button className='activateSound' style={{backgroundColor : soundActive ? "#c1ff72" : "white"}} onClick={()=>{
            new Audio(clickSound).play();
            setsoundActive(true)

            const resetAdhanAudio = () => {
              adhanAudioRef.current.currentTime = 0; // Reset to start
              adhanAudioRef.current.pause(); // Pause the audio
            };

            adhanAudioRef.current.muted  = true;
            adhanAudioRef.current.play().then(()=>{
              adhanAudioRef.current.muted  = false;
              adhanAudioRef.current.onended = resetAdhanAudio;
            })

            setTimeout(() => {
              settoggleMenu(false)
            }, 2000);

            }}>
            {!soundActive && (<>
              تفعيل الصوت<FiVolumeX /> 
              </>)
            }
            {soundActive && (<>
              تم تفعيل الصوت<FiVolume2 />
              </>)
            }
          </button>

          <button className='activateSound' style={{backgroundColor : "#ffc7c7"}} onClick={()=>{navigate("/")}}>خروج<MdLogout /></button>
        </div>
      </div>}

      

      {(state === "normal" || state === "adhan") && (<main style={{marginTop : screen === "H" ? "-1em" : "0px"}}>
        {state === "adhan" && <div className='wamidh' id='wamidhHolder'></div>}
        <h1 className='masjidName' style={{marginLeft : !isMobile && screen === "V" ? '.7em' : '0'}}><MdOutlineMosque style={{transform : !isMobile && screen === "V" ? 'rotate(90deg) ' : 'none'}}/>{masjedData.msqName}</h1>
        <p className='hijriDate' style={{marginLeft : !isMobile && screen === "V" ? '.5em' : '0'}}>{hijriDate}</p>
        <p className='gregorianDate'>{gregorianDate}</p>
        <p className='currentTime' style={{fontSize : !isMobile && screen === "V" ? '5em' : !isMobile && screen === "H" ? '4.5em' : "2.5em" , marginRight : !isMobile && screen === "V" ? '.3em' : '0' }}>{currentTime}</p>
        <p className='NextAdhen'>{nextAdhenIn}</p>
        <div className='centerHolder' style={{fontWeight : !isMobile && screen === "V" ? '900' : '900' }}>  
          {(screen === "V" || isMobile) && (<table style={{fontSize : isMobile ? "1.5em":'6vh' , borderSpacing : isMobile ? "10px":"40px 15px"}} className="prayer-table-v">
            <thead>
              <tr>
                <th className={`${showRemainingForIkama ? 'remainingTimeForIkama' :''}`}>{showRemainingForIkama && (remainingTimeForIkama)}</th>
                <th style={{padding : !isMobile ? ".7em 0" : "0 .7em"}}>الأذان</th>
                <th>الإقامة</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={highlightedSalet == 1 ? "nextSalatActiveV" :'salettdV'} style={{textAlign : "right"}}>الفجر</td>
                <td className={highlightedSalet == 1 ? "nextSalatActiveV" :'salettdV'}>{prayerTimes.Fajr}</td>
                <td className={highlightedSalet == 1 ? "nextSalatActiveV" :'salettdV'}>{establishmentTimes.Fajr}</td>
              </tr>
              <tr>
                <td className={highlightedSalet == 2 ? "nextSalatActiveV" :'salettdV'} style={{textAlign : "right"}}>الشروق</td>
                <td className={highlightedSalet == 2 ? "nextSalatActiveV" :'salettdV'}>{prayerTimes.Sunrise}</td>
                <td className={highlightedSalet == 2 ? "nextSalatActiveV" :'salettdV'}>{establishmentTimes.Sunrise}</td>
              </tr>
              <tr>
                <td className={highlightedSalet == 3 ? "nextSalatActiveV" :'salettdV'} style={{textAlign : "right"}}>الظهر</td>
                <td className={highlightedSalet == 3 ? "nextSalatActiveV" :'salettdV'}>{prayerTimes.Dhuhr}</td>
                <td className={highlightedSalet == 3 ? "nextSalatActiveV" :'salettdV'}>{establishmentTimes.Dhuhr}</td>
              </tr>
              <tr>
                <td className={highlightedSalet == 4 ? "nextSalatActiveV" :'salettdV'} style={{textAlign : "right"}}>العصر</td>
                <td className={highlightedSalet == 4 ? "nextSalatActiveV" :'salettdV'}>{prayerTimes.Asr}</td>
                <td className={highlightedSalet == 4 ? "nextSalatActiveV" :'salettdV'}>{establishmentTimes.Asr}</td>
              </tr>
              <tr>
                <td className={highlightedSalet == 5 ? "nextSalatActiveV" :'salettdV'} style={{textAlign : "right"}}>المغرب</td>
                <td className={highlightedSalet == 5 ? "nextSalatActiveV" :'salettdV'}>{prayerTimes.Maghrib}</td>
                <td className={highlightedSalet == 5 ? "nextSalatActiveV" :'salettdV'}>{establishmentTimes.Maghrib}</td>
              </tr>
              <tr>
                <td className={highlightedSalet == 6 ? "nextSalatActiveV" :'salettdV'} style={{textAlign : "right"}}>العشاء</td>
                <td className={highlightedSalet == 6 ? "nextSalatActiveV" :'salettdV'}>{prayerTimes.Isha}</td>
                <td className={highlightedSalet == 6 ? "nextSalatActiveV" :'salettdV'}>{establishmentTimes.Isha}</td>
              </tr>
            </tbody>
          </table>)}
          {(screen  === "H" && !isMobile) && (<table style={{fontSize : isMobile ? "1.5em":'5.8vh'}} className='prayer-table-h'>
            <thead>
              <tr>
                <th className={`${showRemainingForIkama ? 'remainingTimeForIkama' :''}`}>{showRemainingForIkama && (remainingTimeForIkama)}</th>
                <th className={highlightedSalet == 1 ? "nextSalatActiveH" :'salettd'} >الفجر</th>
                <th className={highlightedSalet == 2 ? "nextSalatActiveH" :'salettd'} >الشروق</th>
                <th className={highlightedSalet == 3 ? "nextSalatActiveH" :'salettd'} >الظهر</th>
                <th className={highlightedSalet == 4 ? "nextSalatActiveH" :'salettd'} >العصر</th>
                <th className={highlightedSalet == 5 ? "nextSalatActiveH" :'salettd'} >المغرب</th>
                <th className={highlightedSalet == 6 ? "nextSalatActiveH" :'salettd'} >العشاء</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>الأذان</th>
                <th className={highlightedSalet == 1 ? "nextSalatActiveH" :'salettd'}>{prayerTimes.Fajr}</th>
                <th className={highlightedSalet == 2 ? "nextSalatActiveH" :'salettd'}>{prayerTimes.Sunrise}</th>
                <th className={highlightedSalet == 3 ? "nextSalatActiveH" :'salettd'}>{prayerTimes.Dhuhr}</th>
                <th className={highlightedSalet == 4 ? "nextSalatActiveH" :'salettd'}>{prayerTimes.Asr}</th>
                <th className={highlightedSalet == 5 ? "nextSalatActiveH" :'salettd'}>{prayerTimes.Maghrib}</th>
                <th className={highlightedSalet == 6 ? "nextSalatActiveH" :'salettd'}>{prayerTimes.Isha}</th>
              </tr>
              <tr>
                <th>الإقامة</th>
                <th className={highlightedSalet == 1 ? "nextSalatActiveH" :'salettd'}>{establishmentTimes.Fajr}</th>
                <th className={highlightedSalet == 2 ? "nextSalatActiveH" :'salettd'}>{establishmentTimes.Sunrise}</th>
                <th className={highlightedSalet == 3 ? "nextSalatActiveH" :'salettd'}>{establishmentTimes.Dhuhr}</th>
                <th className={highlightedSalet == 4 ? "nextSalatActiveH" :'salettd'}>{establishmentTimes.Asr}</th>
                <th className={highlightedSalet == 5 ? "nextSalatActiveH" :'salettd'}>{establishmentTimes.Maghrib}</th>
                <th className={highlightedSalet == 6 ? "nextSalatActiveH" :'salettd'}>{establishmentTimes.Isha}</th>
              </tr>
            </tbody>
          </table>)}
        </div>
        
        <div className='bottomHolder' style={{
            paddingLeft : !isMobile && screen === "V" ? '2em' : !isMobile && screen === "H"  ? '1em' : isMobile  ? '1.7em' : "0",
            paddingTop : !isMobile && screen === "H" ? '0em' : "auto"
          }}>
          <h2 className='ads'>{masjedData.ads}</h2>
          <h3 className='hadeeth'>{masjedData.hadeeth}</h3>
        </div>

        {/* Audio for Adhan */}
        {masjedData.adhanUrl && <audio ref={adhanAudioRef} className='audioHolder' preload='auto' src={masjedData.adhanUrl} controls />}
      </main>)}

      {state === "ikama"  && <div className='blackScreen' style={{writingMode : screen === "V" ? "vertical-rl" : "unset"}} id='blackscreen'>{currentTime}</div>}
    </div>
  );
}



export default Clock;
